export default {
  categories: {
  //   Christmas: {
  //     iconURL: '/magic-menu-icons/icons8-gingerbread-64.png',
  //     collection: 'milq_christmas',
  //     link: 'christmas'
  //   },
  //   Meals: {
  //     iconURL: '/magic-menu-icons/icons8-bento-100.png',
  //     collection: 'frozenly_meals',
  //     link: 'meals'
  //   },
  //   Breakfast: {
  //     iconURL: '/magic-menu-icons/icons8-breakfast-64.png',
  //     collection: 'milq_breakfast',
  //     link: 'breakfast'
  //   },
  //   Vegetables: {
  //     iconURL: '/magic-menu-icons/icons8-peas-96.png',
  //     collection: 'milq_vegetables',
  //     link: 'vegetables'
  //   },
  //   'Whole Foods': {
  //     iconURL: '/magic-menu-icons/icons8-flour-96.png',
  //     collection: 'milq_wholefoods',
  //     link: 'wholefoods'
  //   },
  //   Meat: {
  //     iconURL: '/magic-menu-icons/icons8-souvla-96.png',
  //     collection: 'frozenly_meat',
  //     link: 'meat'
  //   },
  //   Sausages: {
  //     iconURL: '/magic-menu-icons/icons8-sausages-100.png',
  //     collection: 'milq_sausages',
  //     link: 'sausages'
  //   },
  //   Fish: {
  //     iconURL: '/magic-menu-icons/icons8-sashimi-100.png',
  //     collection: 'frozenly_fish',
  //     link: 'fish'
  //   },
  //   Cheese: {
  //     iconURL: '/magic-menu-icons/icons8-cheese-100.png',
  //     collection: 'frozenly_cheese',
  //     link: 'cheese'
  //   },
  //   Eggs: {
  //     iconURL: '/magic-menu-icons/icons8-eggs-100.png',
  //     collection: 'frozenly_eggs',
  //     link: 'eggs'
  //   },
  //   Milk: {
  //     iconURL: '/magic-menu-icons/icons8-milk-carton-96.png',
  //     collection: 'milq_milk',
  //     link: 'milk'
  //   },
  //   Butter: {
  //     iconURL: '/magic-menu-icons/butter.png',
  //     collection: 'frozenly_butter',
  //     link: 'butter'
  //   },
  //   Pantry: {
  //     iconURL: '/magic-menu-icons/icons8-can-soup-96.png',
  //     collection: 'milq_pantry',
  //     link: 'pantry'
  //   },
  //   Bakery: {
  //     iconURL: '/magic-menu-icons/icons8-baguette-bread-96.png',
  //     collection: 'milq_bakery',
  //     link: 'bakery'
  //   },
  //   Pizza: {
  //     iconURL: '/magic-menu-icons/icons8-pizza-five-eighths-100.png',
  //     collection: 'frozenly_pizza',
  //     link: 'pizza'
  //   },
  //   Pies: {
  //     iconURL: '/magic-menu-icons/icons8-pie-96.png',
  //     collection: 'frozenly_pies',
  //     link: 'pies'
  //   },
  //   Sides: {
  //     iconURL: '/magic-menu-icons/icons8-vegan-food-100.png',
  //     collection: 'frozenly_sides',
  //     link: 'sides'
  //   },
  //   'Ice cream': {
  //     iconURL: '/magic-menu-icons/icons8-ice-cream-sundae-96.png',
  //     collection: 'frozenly_icecreams',
  //     link: 'icecream'
  //   },
  //   Chocolate: {
  //     iconURL: '/magic-menu-icons/icons8-chocolate-bar-96.png',
  //     collection: 'milq_chocolate',
  //     link: 'chocolate'
  //   },
  //   Snacks: {
  //     iconURL: '/magic-menu-icons/icons8-potato-chips-96.png',
  //     collection: 'milq_snacks',
  //     link: 'snacks'
  //   },
  //   Desserts: {
  //     iconURL: '/magic-menu-icons/icons8-dessert-96.png',
  //     collection: 'frozenly_desserts',
  //     link: 'desserts'
  //   },
  //   Smoothies: {
  //     iconURL: '/magic-menu-icons/icons8-pineapple-96.png',
  //     collection: 'frozenly_smothies',
  //     link: 'smoothies'
  //   },
  //   Glutenfree: {
  //     iconURL: '/magic-menu-icons/icons8-no-gluten-96.png',
  //     collection: 'milq_glutenfree',
  //     link: 'glutenfree'
  //   },
  //   Hamper: {
  //     iconURL: '',
  //     collection: 'mightplants_hamper',
  //     link: 'hamper'
  //   }
  }
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bigBanner"},[(_vm.showHeadBanner)?_c('div',[(_vm.collection)?_c('div',{staticClass:"bigBanner__top"},[(_vm.collection.title)?_c('h1',[_vm._v("\n        "+_vm._s(_vm.collection.title)+"\n      ")]):_vm._e(),(_vm.collection.description)?_c('p',{staticClass:"more",domProps:{"innerHTML":_vm._s(_vm.collection.description)}}):_vm._e()]):_vm._e()]):_vm._e(),(_vm.collection && _vm.collection.pictures && _vm.collection.pictures.length)?_c('div',{staticClass:"bigBanner__photo"},[_c('picture',[_c('source',{attrs:{"media":"(max-width: 499px)","srcset":_vm.imgix(_vm.collection.pictures[0], {
          height: 300,
          width: 500,
          fit: 'crop'
        })}}),_c('img',{attrs:{"src":_vm.imgix(_vm.collection.pictures[0], {
          width: 1140,
        })}})])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }
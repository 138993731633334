<template>
  <div
    class="addToCartButton"
    v-click-outside="() => { dropdownOpen =  false }"
  >
    <button
      v-if="canBeAddedToCart"
      @click="withoutGuests ? addToCart() : dropdownOpen = !dropdownOpen"
      :disabled="itemData.available_quantity < 1"
      class="addToCartButton__openButton">
      <template v-if="simpleButton">
        <i class="fal fa-plus-circle"></i>
      </template>
      <template v-else>
        <span v-if="itemData.fixed_period">Add to Cart</span>
        <span v-else>Check availability</span>
        <template v-if="!withoutGuests">
          <i v-if="dropdownOpen" class="fal fa-chevron-up"/>
          <i v-else class="fal fa-chevron-down"/>
        </template>
      </template>
    </button>
    <button
      v-else
      class="addToCartButton__openButton"
      disabled>
      <span>Added to cart</span>
      <i class="fal fa-check"/>
    </button>
    <div v-if="dropdownOpen" class="addToCartButton__dropdown">
      <template v-if="itemData.fixed_period">
        <b v-text="`${itemData.fixed_period.start} ― ${itemData.fixed_period.end}`"/>
      </template>
      <template v-else-if="itemType === 'room'">
        <p>Start</p>
        <VueCtkDateTimePicker
          v-model="addToCartStartDate"
          :min-date="minDate"
          format="YYYY-MM-DDTHH:mm:ss"
          color="#000"
          :no-button-now="true"
          :only-date="true"
          :disabled="itemData.fixed_period"
        />
        <p>End</p>
        <VueCtkDateTimePicker
          v-model="addToCartEndDate"
          :min-date="addToCartStartDate"
          format="YYYY-MM-DDTHH:mm:ss"
          color="#000"
          :no-button-now="true"
          :only-date="true"
          :disabled="itemData.fixed_period"
        />
      </template>
      <template v-else-if="itemType=== 'offer'">
        <VueCtkDateTimePicker
          v-model="addToCartStartDate"
          :min-date="minDate"
          format="YYYY-MM-DDTHH:mm:ss"
          color="#000"
          :no-button-now="true"
          :disabled="itemData.fixed_period"
        />
      </template>
      <div class="addToCartButton__guestbox">
        <span>Adults</span>
        <div>
          <button
            @click="guests.adults--"
            class="remove"
            :disabled="guests.adults < 2">
            <i class="fal fa-minus"/>
          </button>
          <b>{{ guests.adults }}</b>
          <button
            @click="guests.adults++"
            class="add">
            <i class="fal fa-plus"/>
          </button>
        </div>
      </div>
      <div class="addToCartButton__guestbox">
        <span>Children</span>
        <div>
          <button
            @click="guests.children--"
            class="remove"
            :disabled="guests.children < 1">
            <i class="fal fa-minus"/>
          </button>
          <b>{{ guests.children }}</b>
          <button
            @click="guests.children++"
            class="add">
            <i class="fal fa-plus"/>
          </button>
        </div>
      </div>
      <div class="addToCartButton__guestbox">
        <span>Infants</span>
        <div>
          <button
            @click="guests.infants--"
            class="remove"
            :disabled="guests.infants < 1">
            <i class="fal fa-minus"/>
          </button>
          <b>{{ guests.infants }}</b>
          <button
            @click="guests.infants++"
            class="add">
            <i class="fal fa-plus"/>
          </button>
        </div>
      </div>
      <BlackButton
        @click.native="addToCart"
        inverted
        v-text="`Add to cart`"
        :disabled="!formComplete"
        style="width: 100%"
      />
    </div>
  </div>
</template>

<script>
import BlackButton from '@/components/BlackButton.vue'
import currencyList from '@/assets/currencyList.json'
import ClickOutside from 'vue-click-outside'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker'
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css'

export default {
  name: 'OfferAddToCartButton',
  components: {
    BlackButton,
    VueCtkDateTimePicker
  },
  directives: {
    ClickOutside
  },
  computed: {
    itemType () {
      return this.itemData.item_type
    },
    formComplete () {
      switch (this.itemType) {
        case 'offer':
          return this.addToCartStartDate
        case 'room':
          return this.addToCartStartDate && this.addToCartEndDate
        default:
          return true
      }
    },
    getPrice () {
      // Converts a price from minor currency units to major ones
      return this.itemData.price / Math.pow(10, currencyList[this.itemData.currency]['fractionSize'])
    },
    minDate () {
      if (this.itemData.fixed_period) {
        return this.itemData.fixed_period.start
      }
      if (this.itemData.available_period) {
        return this.itemData.available_period.start
      }
      return ''
    },
    withoutGuests () {
      return true // TODO field in BE
    },
    addedToCart () {
      return this.$store.state.cart.some((item) => item.id === this.itemData.id)
    },
    canBeAddedToCart () {
      return !this.addedToCart || this.withoutGuests
    }
  },
  props: {
    itemData: Object,
    simpleButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dropdownOpen: false,
      currencyList: currencyList,
      addToCartStartDate: '',
      addToCartEndDate: '',
      guests: {
        adults: 0,
        children: 0,
        infants: 0
      }
    }
  },
  mounted () {
    this.addToCartStartDate = this.minDate
    if (this.itemData.fixed_period) {
      this.addToCartStartDate = this.itemData.fixed_period.start
      this.addToCartEndDate = this.itemData.fixed_period.end
    }
  },
  methods: {
    addToCart () {
      switch (this.itemType) {
        case 'offer':
          this.itemData.guests = this.guests
          this.itemData['time_start'] = this.addToCartStartDate.split('T')[0] + 'T00:00:00'
          break
        case 'room':
          this.itemData.guests = this.guests
          this.itemData.period = {
            start: this.addToCartStartDate.split('T')[0] + 'T00:00:00',
            end: this.addToCartEndDate.split('T')[0] + 'T00:00:00'
          }
          break
      }
      this.$emit('addItemToCart', this.itemData)
      this.dropdownOpen = false
    }
  }
}
</script>

<style scoped lang="stylus">
.addToCartButton
  display inline-block
  position relative
  div
    white-space normal
  &__openButton
    position relative
    display inline-flex
    margin-top 18px
    align-items center
    justify-content center
    font inherit
    border none
    font-weight 700
    font-size 1rem
    padding 0
    padding 10px 20px
    margin-bottom 14px
    background-color white
    border-radius 6px
    cursor pointer
    background-color #fff
    border 1px solid #ccc
    box-shadow 0 0 12px rgba(0, 0, 0, .05)
    transition-duration .12s
    i
      margin-left 10px
    &:hover
      border-color #aaa
    &[disabled]
      cursor default
      &:hover
        border-color #ddd
  &__openButton.simpleButton
    opacity 1
  &__dropdown
    position absolute
    width 330px
    padding 10px 16px 5px 16px
    border 1px solid #ccc
    background-color white
    box-shadow 0 0 12px rgba(0, 0, 0, .05)
    border-radius 6px
    top 70px
    p
      margin-bottom 3px
  &__guestbox
    width 100%
    margin 14px 0 5px 0
    display flex
    padding 01px
    align-items center
    justify-content space-between
    button.add, button.remove
      width 30px
      height 30px
      border-radius 40px
      border 1px solid #999
      color black
      background-color white
      font-size 14px
      display inline-flex
      align-items center
      justify-content center
      cursor pointer
      transition-duration .1s
      i
        margin-top 2px
        line-height 0
      &:hover
        opacity .8
      &:active, &[disabled]
        opacity .4
      &[disabled]
        cursor default
    button.remove
      margin-right 10px
    button.add
      margin-left 10px
</style>

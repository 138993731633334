<template>
  <div class="recipe">
    <h1 class="recipe__title">Plant-based Recipes</h1>
    <Listing
      :collection="collection"
      :userLogged="$store.getters.userLoggedIn"
      @addItemToCart="$emit('addProductToCart', $event)"
    />
    <!-- <div class="recipe__items-container" v-if="loading">
      <LoadingProductCard v-for="index in 9" :key="`loadingRecipe_${index}`" />
    </div>

    <div class="recipe__items-container" v-else>
      <ProductCard
        class="recipe__item"
        style="min-height: unset"
        v-for="(recipe, index) in recipeItems"
        :key="`recipe_${index}`"
        :product="recipe"
      />
    </div> -->
  </div>
</template>

<script>
// import httpClient from '@/services/httpClient'
// import LoadingProductCard from '@/components/LoadingProductCard.vue'
// import ProductCard from '@/components/ProductCard.vue'
import { getMetaTags } from '@/seo.js'

export default {
  components: {
    // LoadingProductCard,
    // ProductCard
  },
  props: {
    collection: {
      type: String,
      default: null
    }
  },
  data: () => ({
    loading: false,
    recipeItems: []
  }),
  mounted () {
    // this.getRecipeItems()
  },
  methods: {
    // async getRecipeItems () {
    //   try {
    //     this.loading = true
    //     // fetch all items
    //     // ToDo
    //     const recipes = this.collection
    //       ? await httpClient.get(`/items?format=full&collections=${this.collection}`)
    //       : await httpClient.get('/items?format=full')
    //     // filter based on item_type to get only recipe items
    //     const filteredRecipes = recipes.data.filter(
    //       (item) => item.item_type === 'recipe'
    //     )
    //     this.recipeItems = filteredRecipes
    //     // for (let recipe in filteredRecipes) {
    //     //   this.recipeItems.push(recipe)
    //     //   this.recipeItems.push({})
    //     // }
    //   } catch (e) {
    //     console.log(e)
    //   } finally {
    //     this.loading = false
    //   }
    // }
  },
  metaInfo () {
    const seo = {
      metaTitle: 'Recipes and Food Guide - Cook & Prosper',
      metaDescription: 'Cook and Prosper - The plant-based recipes'
    }
    return {
      title: 'Recipes and Food Guide - Cook & Prosper',
      meta: getMetaTags(seo)
    }
  }
}
</script>

<style lang="stylus" scoped>
.recipe {
  &__title {
    text-align: left;
    font-size: 21px;

    @media screen and (max-width: 500px) {
      font-size: 15px;
    }
  }

  &__items-container {
    display: grid;
    // columns: 4 auto;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    gap: 30px;
    grid-template: 1fr / 1fr 1fr 1fr 1fr;
    padding-bottom: 30px;

    @media screen and (max-width: 991px) {
      columns: 3 auto;
    }
    @media screen and (max-width: 650px) {
      // display: grid;
      // grid-template: unquote('1fr / minmax(0, 1fr) minmax(0, 1fr)');
      // columns: auto;
      // max-width: 460px;
      columns: 2 auto;
    }

    @media screen and (max-width: 500px) {
      columns: 1 auto;
      // grid-template: 1fr / 1fr 1fr 1fr 1fr;
      gap: 10px;
    }
  }

  &__item {
    // display: block;
    margin-bottom: 15px;
    page-break-inside: avoid;
    break-inside: break;
  }
  /deep/.cardHolder .itemCard {
    background-color: #F5FBFF;
    border-radius: 5px;
    &__trimmedDescription {
      color: #000000;
    }
  }
}
</style>
